import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import UnlockIcon from '../../static/assets/images/passwordUnlock.svg'
import PasswordText from '../../static/assets/images/password_text.svg'
const PasswordProtect = ({ password, children }) => {
  const [unlocked, setUnlocked] = useState(false)
  const passwordRef = useRef(null)
  const [error, setError] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    if (passwordRef.current.value === password) {
      setUnlocked(true)
    } else {
      passwordRef.current.value = ''
      setError(true)
    }
  }

  const handleChange = () => {
    if (error) {
      setError(false)
    }
  }

  if (unlocked) {
    return children
  }

  return (
    <div className='password-protect__container'>
      <Helmet title='Password Protected | Boldbreed' />
      <section className='password-protect__logo'>
        <span
          style={{
            backgroundImage: 'url(/assets/images/logo-grouped.svg)',
          }}
        />
        <span
          style={{
            backgroundImage: 'url(/assets/images/logo-grouped-white.svg)',
          }}
        />
      </section>
      <form className='form' onSubmit={handleSubmit}>
        <div className='form__body'>
          <div className='form__row'>
            <PasswordText />
            <div className='form__controls'>
              <input
                id='password'
                className='field'
                type='password'
                placeholder='Password'
                ref={passwordRef}
                onChange={handleChange}
                required
              />
            </div>
            {error && (
              <div className='error'>
                The password entered was incorrect. Try again.
              </div>
            )}
          </div>
          <div className='form__actions'>
            <button
              type='submit'
              className='btn-arrow btn-arrow--small form__btn'
            >
              Unlock
              <span>
                <UnlockIcon />
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PasswordProtect
