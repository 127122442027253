import React from 'react'
import $ from 'jquery'
import ReactJoin from 'react-join'

import imgix from '../../utils/imgix'
import MessageIcon from '../../../static/assets/images/message.svg'

const Author = ({ artist, displayRequestArtist = true, customCTAText }) => {
  const { artist_category_name, acf } = artist

  let locationText = ''
  if (acf.berlin_based !== null && acf.berlin_based === true) {
    locationText = 'Berlin Based'
  } else if (acf.other_location !== null) {
    locationText = acf.other_location
  }

  let artistPic = '/assets/images/placeholder.png'
  if (acf.face && !acf.face.source_url.includes('placeholder')) {
    artistPic = acf.face.source_url
  }

  const toggleAccordion = () => {
    const acc = $('.js-accordion-trigger')
    if (acc.parent().hasClass('expanded')) {
      acc
        .next()
        .slideUp()
        .parent()
        .removeClass('expanded')
    } else {
      acc
        .next()
        .slideToggle()
        .parent()
        .addClass('expanded')
        .siblings()
        .removeClass('expanded')
        .children('.accordion-body')
        .slideUp()
    }
  }

  const artistCategoryName = () => (
    <ReactJoin separator={<br />}>{artist_category_name}</ReactJoin>
  )

  return (
    <section className='section-author'>
      <div className='section__inner'>
        <header className='section__head'>
          <h2>
            <strong className='mobile-only'>About</strong>
            {`${acf.first_name} ${acf.second_name}`}
            <span>{artistCategoryName()}</span>
          </h2>
        </header>

        <div className='section__content'>
          <div className='section__image'>
            <img
              src={imgix.buildURL(artistPic, {
                w: 400,
                h: 400,
              })}
              alt=''
            />

            <p className='section__location'>{locationText}</p>
          </div>

          <div className='section__entry'>
            <div className='list-info'>
              <ul>
                {acf.software && (
                  <li>
                    <strong>Software</strong>

                    <div dangerouslySetInnerHTML={{ __html: acf.software }} />
                  </li>
                )}

                <li>
                  <strong>Agentur</strong>

                  {acf.artist_type === 'audio' ? (
                    <img src='/assets/images/logo-c-ya-dark.svg' alt='' />
                  ) : (
                    <img src='/assets/images/logo-boldgreed-dark.svg' alt='' />
                  )}
                </li>
              </ul>
            </div>

            <div className='accordion artist'>
              <div className='accordion__section'>
                <div className='accordion__head js-accordion-trigger'>
                  {/* eslint-disable-next-line */}
                  <p onClick={toggleAccordion}>
                    <strong>READ MORE</strong>
                  </p>
                </div>

                <div className='accordion__body'>
                  <div dangerouslySetInnerHTML={{ __html: acf.cv }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <aside className='section__aside'>
          <aside className='section__aside-inner'>
            <h3
              className='section__aside-inner custom-cta-text'
              onClick={() => {
                window.open(`mailto:${acf.contact_information.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+)/gi)[0]}`)
              }}
            >
              {customCTAText ? customCTAText : 'INTERESTED?'}
            </h3>

            {typeof acf.contact_information !== 'undefined' &&
              acf.contact_information && (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: acf.contact_information,
                    }}
                  />
                  <p>&nbsp;</p>
                </>
              )}

            {displayRequestArtist && (
              <a href='/contact/' className='btn-arrow btn-arrow--small '>
                Request
                <br />
                artist
                <span>
                  <MessageIcon />
                </span>
              </a>
            )}
          </aside>
        </aside>
      </div>
    </section>
  )
}

export default Author
