import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'
import loadable from '@loadable/component'
import slugify from 'slugify'
import qs from 'qs'
import wrapText from 'wrap-text'
import { pastExpirationDate } from '../utils/utils'

import FramelessLayout from '../components/layouts/FramelessLayout'
import PasswordProtect from '../components/PasswordProtect'
import Footer from '../components/shared/Footer'

import Author from '../components/shared/Author'

const IsotopeGrid = loadable(() => import('../components/shared/IsotopeGrid'))

import imgix from '../utils/imgix'

const PitchTitle = ({ title, artist }) => {
  const titleLines = wrapText(title, 10).split('\n')
  return (
    <section className='section-title pitch-title'>
      <svg
        viewBox={`0 -2 200 ${titleLines.length * 12}`}
        fill='#ffffff'
        className='desktop'
      >
        {titleLines.map((line, index) => (
          <text
            key={line}
            x='1'
            y={9 + index * 12}
            fill='#2F3631'
            fontFamily="'Reem Kufi', sans-serif"
          >
            {line.toUpperCase()}
          </text>
        ))}
      </svg>
      <svg
        viewBox={`0 0 250 ${titleLines.length * 21}`}
        fill='#ffffff'
        className='mobile'
      >
        {titleLines.map((line, index) => (
          <text
            key={line}
            x='125'
            y={15 + index * 21}
            fill='#2F3631'
            letterSpacing='0.2em'
            textAnchor='middle'
            fontFamily="'Reem Kufi', sans-serif"
          >
            {line.toUpperCase()}
          </text>
        ))}
      </svg>
    </section>
  )
}

const ArtistTitle = ({ artist }) => {
  const {
    artist_category_name,
    acf: { first_name, second_name },
  } = artist
  const category = artist_category_name.join(', ')
  const text = `${first_name} ${second_name} / ${category}`
  return (
    <section className='section__artist'>
      <div className='section__artist--title'>Our pick for your project</div>
      <div className='section__artist--title mobile-only'>
        {text.toUpperCase()}
      </div>
      <div className='section__artist--name'>
        <svg viewBox='0 0 250 8' fill='#ffffff' className='desktop'>
          <text
            x='0'
            y='7'
            font-size='6px'
            fill='#2F3631'
            letterSpacing='0.2em'
            fontFamily="'Reem Kufi', sans-serif"
          >
            {text.toUpperCase()}
          </text>
        </svg>
      </div>
    </section>
  )
}

const PitchPage = ({ data }) => {
  const { wordpressWpPitch: post } = data

  const { slug } = post
  const { title, videos, password, expiration_date } = post.acf
  const artist = post.acf.artist[0]
  const {
    artist_category_name,
    acf: { first_name, second_name },
  } = artist

  // prepare videos
  const items =
    Array.isArray(videos) &&
    videos.map(video => {
      let videoPoster = '/assets/images/placeholder.png'

      if (video.youtube_video.thumbnails.maxres !== null) {
        videoPoster = video.youtube_video.thumbnails.maxres.url
      } else if (video.youtube_video.thumbnails.standard !== null) {
        videoPoster = video.youtube_video.thumbnails.standard.url
      } else if (video.youtube_video.thumbnails.high !== null) {
        videoPoster = video.youtube_video.thumbnails.high.url
      } else if (video.youtube_video.thumbnails.medium !== null) {
        videoPoster = video.youtube_video.thumbnails.medium.url
      } else if (video.youtube_video.thumbnails.default !== null) {
        videoPoster = video.youtube_video.thumbnails.default.url
      }

      return {
        title: video.youtube_video.title.split('//')[0],
        videoUrl: video.snippet_url || '',
        videoPoster: videoPoster,
        videoPopup: `https://www.youtube.com/watch?v=${video.youtube_video.video_id}`,
        videoId: video.youtube_video.video_id,
        articleType: 'small',
      }
    })

  if (pastExpirationDate(expiration_date)) {
    return <Redirect to='/' />
  }

  return (
    <FramelessLayout>
      <PasswordProtect password={password}>
        <Helmet
          title={`${title} | Boldbreed`}
          bodyAttributes={{
            class: 'has-grouped-logo has-back-button',
          }}
        >
          <html lang="en"/>
          <link rel='canonical' href={`https://boldbreed.de/pitch/${slug}/`} />
        </Helmet>
        <div className='wrapper'>
          <div className='wrapper__inner'>
            <header className='header'>
              <div className='shell'>
                <div className='header__inner pitch-header'>
                  <a href='/' className='logo-centered'>
                    <span
                      style={{
                        backgroundImage: 'url(/assets/images/logo-grouped.svg)',
                      }}
                    ></span>
                    <span
                      style={{
                        backgroundImage:
                          'url(/assets/images/logo-grouped-white.svg)',
                      }}
                    ></span>
                  </a>
                </div>
              </div>
            </header>

            <PitchTitle title={title} artist={artist} />
            <ArtistTitle artist={artist} />

            {Array.isArray(items) && (
              <IsotopeGrid type='projects' items={items}>
                <div className='section__content'>
                  <div className='tabs'>
                    <div className='tabs__body'>
                      <div id='projects' className='tab is-active'>
                        <div
                          className='grid grid--controls-overlap'
                          data-type='projects'
                        >
                          <div className='grid__inner'></div>

                          <div className='grid__controls'>
                            <a href='#' className='btn load-more'>
                              Load <br />
                              More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IsotopeGrid>
            )}

            <Author
              artist={artist}
              displayRequestArtist={false}
              customCTAText='Pick this artist'
            />

            <Footer />
          </div>
        </div>
      </PasswordProtect>
    </FramelessLayout>
  )
}

export default PitchPage

export const pageQuery = graphql`
  query PitchPostByID($id: String!) {
    wordpressWpPitch(id: { eq: $id }) {
      slug
      acf {
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
        expiration_date
        title
        password
        videos {
          slug
          youtube_video {
            thumbnails {
              maxres {
                url
              }
              standard {
                url
              }
              high {
                url
              }
              medium {
                url
              }
              default {
                url
              }
            }
            video_id
            channel_id
            channel_title
            title
            description
            category_id
            category
            duration
            iso_duration
            human_duration
            definition
            video_id
            published
          }
        }
        artist {
          id
          artist_category
          artist_category_name
          acf {
            first_name
            second_name
            cv
            artist_type
            software
            face {
              source_url
            }
            berlin_based
            contact_information
          }
        }
      }
    }
  }
`
